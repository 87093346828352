export const top = {
    marginTop: '1rem',
};
export const attachementStyle = {
    marginTop: '10px',
    width: '100%',
    justifyContent: 'start',
    display: 'flex',
    padding: '30px',
    gap: '15px',
    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
    borderRadius: '8px',
    flexWrap: 'wrap',
};
export const handleDentconfirmation = {
    marginTop: '20px',
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
    padding: '30px',
    gap: '15px',
};
export const emptySection = {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '0 auto',
    flexDirection: 'column',
    gap: '20px',
};
export const treatmentSection = {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: '20px',
};
